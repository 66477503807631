import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import './OS_Page.css';
import { AppleIcon, FolderIcon, TypewriterIcon, PaperIcon, TrashIcon } from './SVGIcons';
import TextEditor from './TextEditor';
import Screen1 from './Screen1.js';
import seanImage from './sean.png';
const MenuBar = () => (
  <div className="menu-bar">
    <span className="apple-icon"><AppleIcon /></span>
    <span>File</span>
    <span>Edit</span>
    <span>View</span>
    <span>Special</span>
  </div>
);

const DesktopIcon = ({ label, icon, onDoubleClick, onDragStart, onDragEnd, position }) => {
  const [lastTap, setLastTap] = useState(0);

  const handleTap = (e) => {
    console.log("tap")
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTap;
    if (tapLength < 300 && tapLength > 0) {
      onDoubleClick();
    }
    setLastTap(currentTime);
  };

  return (
    <Draggable
      bounds="parent"
      onStart={onDragStart}
      onStop={onDragEnd}
      defaultPosition={position}
    >
      <div 
        className="desktop-icon" 
        onDoubleClick={onDoubleClick}
        onTouchStart={handleTap}
        onTouchEnd={handleTap}
      >
        <div className="icon">
          {icon}
        </div>
        <div className="label">{label}</div>
      </div>
    </Draggable>
  );
};

const OS_Page = () => {
  const [openWindows, setOpenWindows] = useState({});
  const [notes, setNotes] = useState([]);
  const [draggingNote, setDraggingNote] = useState(null);
  const [trashPosition, setTrashPosition] = useState({ x: window.innerWidth - 250, y: window.innerHeight - 300 });
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  const sections = [
    { id: 'about', label: 'About Me', icon: <PaperIcon color="black" /> },
    { id: 'resume', label: 'Resume', icon: <PaperIcon color="black" /> },
    { id: 'projects', label: 'Projects', icon: <FolderIcon /> },
    { id: 'contact', label: 'Contact', icon: <FolderIcon /> },
    { id: 'text-editor', label: 'Notes', icon: <TypewriterIcon /> },
  ];

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 700;
      setIsMobile(mobile);
      setWindowSize({ width: window.innerWidth - 100, height: window.innerHeight -100 });
      if (mobile) {
        setTrashPosition({ x: window.innerWidth - 100, y: window.innerHeight - 400 });
      } else {
        setTrashPosition({ x: window.innerWidth - 250, y: window.innerHeight - 300 });
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSaveNote = (fileName, content) => {
    // Calculate position for new note
    const notesCount = notes.length;
    const rightOffset = windowSize.width - 150; // Start from right side, adjust 150px for icon width
    const topStart = 100; // Start from top with some padding
    const verticalSpacing = 100; // Space between notes
  
    // Calculate new position
    const newPosition = {
      x: rightOffset,
      y: topStart + (notesCount * verticalSpacing)
    };
  
    const newNote = { 
      id: Date.now(), 
      label: fileName, 
      content,
      position: newPosition // Store position with note
    };
    
    setNotes([...notes, newNote]);
    setOpenWindows(prev => ({ ...prev, 'text-editor': false }));
  };

  const handleOpenWindow = (id) => {
    const position = isMobile
      ? { x: 5, y: 35 }
      : {
          x: (windowSize.width / 2) - 400,  // Half the max-width of window (800px/2)
          y: (windowSize.height / 2) - 300  // Half the max-height of window (600px/2)
        };
    setOpenWindows(prev => ({ ...prev, [id]: position }));
  };

  const handleCloseWindow = (id) => {
    setOpenWindows(prev => ({ ...prev, [id]: false }));
  };

  const handleNoteDragStart = (noteId) => {
    setDraggingNote(noteId);
  };
  const handleNoteDragEnd = (e, data) => {
    const trashRect = {
      left: trashPosition.x - 30,    // Increased detection area
      right: trashPosition.x + 200,  // Increased from 160 to 200
      top: trashPosition.y - 30,     // Added padding above
      bottom: trashPosition.y + 200  // Increased from 160 to 200
    };
  
    if (
      draggingNote && 
      data.x >= trashRect.left &&
      data.x <= trashRect.right &&
      data.y >= trashRect.top &&
      data.y <= trashRect.bottom
    ) {
      setNotes(prevNotes => prevNotes.filter(note => note.id !== draggingNote));
      setOpenWindows(prev => ({ ...prev, [`note-${draggingNote}`]: false }));
    }
    setDraggingNote(null);
  };
  const handleTrashDragStop = (e, data) => {
    setTrashPosition({ x: data.x, y: data.y });
  };

  const getMobilePosition = (index) => {
    if (isMobile) {
     
      return { x: 10, y: 35 + index * 60 };
    }
    return undefined;
  };
  

  return (
    <div className="classic-mac">
      <MenuBar />
      <div className="desktop">
        {sections.map((section, index) => (
          <DesktopIcon
            key={section.id}
            label={section.label}
            icon={section.icon}
            onDoubleClick={() => handleOpenWindow(section.id)}
            position={getMobilePosition(index)}
          />
        ))}
 {notes.map((note, index) => (
  <DesktopIcon
    key={note.id}
    label={note.label}
    icon={<PaperIcon color="black" />}
    onDoubleClick={() => handleOpenWindow(`note-${note.id}`)}
    onDragStart={() => handleNoteDragStart(note.id)}
    onDragEnd={handleNoteDragEnd}
    position={note.position} // Use the stored position
  />
))}
        <DesktopIcon
          label="Trash"
          icon={<TrashIcon />}
          position={trashPosition}
          onDragEnd={handleTrashDragStop}
          onDoubleClick={() => {return "nothing"}}
        />
        {Object.entries(openWindows).map(([id, position]) => {
          if (!position) return null;
          if (id === 'text-editor') {
            return (
              <TextEditor 
                key={id}
                onSave={handleSaveNote} 
                onClose={() => handleCloseWindow(id)}
                position={position}
              />
            );
          }
          if (id.startsWith('note-')) {
            const note = notes.find(n => `note-${n.id}` === id);
            return (
              <Screen1
                key={id}
                title={note.label}
                onClose={() => handleCloseWindow(id)}
                position={position}
              >
                <pre>{note.content}</pre>
              </Screen1>
            );
          }
          return (
            <Screen1
              key={id}
              title={sections.find(s => s.id === id).label}
              onClose={() => handleCloseWindow(id)}
              position={position}
            >
 {id === 'about' ? (
  <div className="about-content" style={{ padding: '20px' }}>
    <div className="about-header" style={{ float: 'right', marginLeft: '20px', marginBottom: '15px', width: '20%' }}>
      <img src={seanImage} alt="Sean's Profile" style={{ width: '100%', height: 'auto', display: 'block' }} />
    </div>
    <div style={{ fontSize: '17.7px', lineHeight: '1.6' }}>
      <p style={{ marginBottom: '20px' }}>Sean is a tech-savvy leader with a passion for solving complex problems and a track record of successfully driving application migrations, modernizations, and full stack development projects. With extensive experience as a Lead Software Engineer, Sean has a proven ability to lead cross-functional teams and collaborate with SMEs to develop effective solutions that meet business needs.</p>
      
      <p style={{ marginBottom: '20px' }}>He has a talent for navigating the entire software development lifecycle, from requirements gathering to production deployments, and possesses a wealth of experience in migrating applications from on-prem environments to modern cloud environments. Sean's innovative approach and expertise in working closely with clients make him an invaluable asset to any organization seeking to stay ahead of the curve in today's rapidly evolving technology landscape.</p>

      <p style={{ marginBottom: '20px' }}>Born and raised in New Orleans, Sean brings a unique perspective to his work. As an Eagle Scout, he developed strong leadership and problem-solving skills that he continues to apply in his professional life. His passion for the outdoors is reflected in his love for hiking and camping.</p>

      <p style={{ marginBottom: '20px' }}>When not working on software projects, Sean can often be found pursuing his hobby of refurbishing vintage technology. He particularly enjoys restoring classic video game consoles and 70s stereo equipment, combining his technical expertise with his appreciation for vintage electronics.</p>
    </div>
  </div>
) : id === 'resume' ? (
  <div className="resume-content">
    <div className="resume-text">
      <div className="resume-columns">
        {/* Main Work Experience Column */}
        <div className="resume-main">
          <h2>Work Experience</h2>
          
          <h3>Software Engineer - DXC Technology (2019 – 2022)</h3>
          <div>
            <h4>Application Migrations (Lead Software Engineer)</h4>
            <ul>
              <li>Led a team of developers in analyzing application codebases for migration to new FMO environments</li>
              <li>Determined changes required for applications to function in development, ITG, staging, and production environments</li>
              <li>Developed comprehensive test plans to validate correct implementation of changes</li>
              <li>Ensured applications would execute properly in new environments</li>
              <li>Reviewed test plans and objective evidence with SMEs to obtain sign-off</li>
              <li>Developed a file system crawler to automate the updating of key lines, including server endpoints and leveling of remote server cluster as well as on prem</li>
            </ul>

            <h4>Devops Consultant</h4>
            <h5>Grocery Store Client (Lead Software Engineer)</h5>
            <ul>
              <li>Joined an in-flight project to assist a Cognizant delivery team</li>
              <li>Diagnosed critical issues causing application failures in production environment</li>
              <li>Identified flawed testing routines that didn't account for peak throughput</li>
              <li>Resolved issues causing near-complete shutdowns of client's sales ability</li>
              <li>Implemented proper stress testing and queue management</li>
            </ul>

            <h5>Retail Client (Lead Software Engineer)</h5>
            <ul>
              <li>Produced comprehensive application inventory audit workbook</li>
              <li>Led interviews with client personnel and SMEs</li>
              <li>Provided 6R-style disposition recommendations for applications</li>
              <li>Upskilled on Google's Stratozone and Stratoprobe assessment tools</li>
              <li>Delivered assessment reports to the client</li>
            </ul>
          </div>
        </div>

        {/* Skills Column */}
        <div className="resume-skills">
          <h2>Skills</h2>
          
          <h3>Professional Skills</h3>
          <ul>
            <li>Team Lead Experience</li>
            <li>Project Management</li>
            <li>Scrum Master (acting)</li>
            <li>Technical Presentation</li>
            <li>Client Interaction</li>
            <li>Cross-functional Collaboration</li>
            <li>Code Review and Analysis</li>
            <li>Requirements Gathering</li>
            <li>Technical Writing</li>
            <li>Test Plan Development</li>
          </ul>

          <h3>Technical Skills</h3>
          <ul>
            <li>JavaScript</li>
            <li>Python</li>
            <li>React.js</li>
            <li>Angular.js</li>
            <li>Node.js</li>
            <li>Next.js</li>
            <li>Vue.js</li>
            <li>MySQL</li>
            <li>MongoDB</li>
            <li>AWS</li>
            <li>Azure</li>
            <li>GCP</li>
            <li>Git</li>
            <li>Agile/Scrum</li>
            <li>Veracode</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
) : (
  <div>Content for {sections.find(s => s.id === id).label}</div>
)}
            </Screen1>
          );
        })}
      </div>
    </div>
  );
};

export default OS_Page;