import React from 'react';
import Draggable from 'react-draggable';

const Screen1 = ({ title, onClose, children, position }) => {
  const handleCloseClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  return (
    <Draggable handle=".window-header" bounds="parent" defaultPosition={position}>
      <div className="window">
        <div className="window-header">
          <div 
            className="" 
            onClick={handleCloseClick}
            onTouchEnd={handleCloseClick}
          >
            X
          </div>
          <span>{title}</span>
        </div>
        <div className="window-content">
          {children}
        </div>
      </div>
    </Draggable>
  );
};

export default Screen1;