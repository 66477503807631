import React, { useState } from 'react';
import Screen1  from './Screen1';  

const TextEditor = ({ onSave, onClose, position }) => {
  const [text, setText] = useState('');
  const [fontSize, setFontSize] = useState('16px');
  const [fontFamily, setFontFamily] = useState('Arial');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);

  const handleSave = () => {
    const fileName = prompt('Enter a name for your note:');
    if (fileName) {
      onSave(fileName, text);
    }
  };

  return (
    <Screen1 title="Notes" onClose={onClose} position={position}>
      <div className="text-editor">
        <div className="toolbar">
          <select value={fontSize} onChange={(e) => setFontSize(e.target.value)}>
            <option value="12px">12</option>
            <option value="16px">16</option>
            <option value="20px">20</option>
          </select>
          <select value={fontFamily} onChange={(e) => setFontFamily(e.target.value)}>
            <option value="Arial">Arial</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Courier">Courier</option>
          </select>
          <button onClick={() => setIsBold(!isBold)}>B</button>
          <button onClick={() => setIsItalic(!isItalic)}>I</button>
          <button onClick={handleSave}>Save</button>
        </div>
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          style={{
            fontSize,
            fontFamily,
            fontWeight: isBold ? 'bold' : 'normal',
            fontStyle: isItalic ? 'italic' : 'normal',
          }}
        />
      </div>
    </Screen1>
  );
};

export default TextEditor;